import React from "react";
import styled from "styled-components";
import { Hero, HeroHeadline } from "../../../components/hero";
import MainSection from "../../../components/main-section/main-section";
import SectionGallery from "../../../components/section-gallery";
import AmsterdamLiving from "../../../sections/office/amsterdam/amsterdam-living";
import AmsterdamInfo from "../../../sections/office/amsterdam/amsterdam-info";
import AmsterdamTransport from "../../../sections/office/amsterdam/amsterdam-transport";
import { JobsSearchTile, TileNavigation } from "../../../components/tile-navigation";
import { JobOffersCountInterface } from "../../../graphql/jobs";
import Layout from "../../../components/layout";
import { graphql, PageProps } from "gatsby";
import { AboutAmsterdamPageContentInterface } from "../../../graphql/about-amsterdam-page";
import { transformHeroData } from "../../../utilities/pimcore-data-transformer";
import Scrollspy from "../../../components/scrollspy/scrollspy";

interface AboutAmsterdamPageDataInterface {
  pimcore: {
    content: AboutAmsterdamPageContentInterface;
    jobOffersCount: JobOffersCountInterface;
  };
}

const labels = [
  "Your place to live, work, enjoy!",
  "Living in Amsterdam",
  "Easy to get around",
  "We just love this city",
];

const AboutAmsterdamPage: React.FC<PageProps<AboutAmsterdamPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="office"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <HeroHeadline dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
        </Hero>
      </Header>
      <Main>
        <MainSection
          headline={content.mainSectionHeadline}
          subHeadline={content.mainSectionSubheadline}
          content={content.mainSectionContent}
          background={content.mainSectionBackground}
          backgroundMobile={content?.mainSectionBackgroundMobile}
        >
          <SectionGallery images={content.gallery} />
        </MainSection>
        <ScrollspyWrapper>
          <AmsterdamLiving content={content.living} />
          <AmsterdamInfo content={content.info} />
        </ScrollspyWrapper>
        <AmsterdamTransport content={content.transport} />
        <TileNavigation top={"work"} bottom={"team"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
      <Scrollspy labels={labels} gap={6} />
    </Layout>
  );
};

const Header = styled.header.attrs({ className: "h-screen" })``;
const Main = styled.main.attrs({ className: "x-background overflow-x-hidden -mt-24" })``;
const ScrollspyWrapper = styled.div.attrs({ className: "scrollspy" })``;

export const query = graphql`
  query {
    pimcore {
      ...aboutAmsterdamPageData
      ...jobOffersCount
    }
  }
`;

export default AboutAmsterdamPage;

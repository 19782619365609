import React from "react";
import styled from "styled-components";
import FactsBox from "../../../components/facts-box";
import { Headline3, Paragraph } from "../../../components/typography";
import { theme } from "../../../styles/styles";
import Video from "../../../components/media/video";
import QuoteElement from "../../../components/quote-element/quote-element";
import { PageElement } from "../../../graphql/page-common";
import {
  getElementFact,
  getElementImage,
  getElementText,
  getElementVideo,
} from "../../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../../components/pimcore-image";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../../utilities/animation-helper";

interface AmsterdamTransportProps {
  content: Array<PageElement>;
}

interface ImageWrapperProps {
  $size?: "small";
  $mobileHidden?: boolean;
  $withGap?: boolean;
  $margin?: "left";
}

const AmsterdamTransport: React.FC<AmsterdamTransportProps> = ({ content }) => {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const firstSection = React.useRef<HTMLDivElement>(null);
  const secondSection = React.useRef<HTMLDivElement>(null);
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    const firstChildrenIndexSection = [1, 0, 2, 3, 4, 5];
    const firstChildrenIndexSectionMobile = [0, 1, 2, 3, 4, 5];

    const firstIndexSection = matchMobile ? firstChildrenIndexSectionMobile : firstChildrenIndexSection;

    const secondChildrenIndexSection = [1, 0, 2];
    const secondChildrenIndexSectionMobile = [0, 1, 2];
    const secondIndexSection = matchMobile ? secondChildrenIndexSectionMobile : secondChildrenIndexSection;

    tl1.current = onScrollAnimationDesktop(
      firstSection.current,
      returnRefArray(firstSection.current, firstIndexSection, [5]),
      matchMobile
    );
    tl2.current = onScrollAnimationDesktop(
      secondSection.current,
      returnRefArray(secondSection.current, secondIndexSection),
      matchMobile,
      ["6rem", "10rem"]
    );
  }, [matchMobile]);

  return (
    <>
      <ScrollWrapper>
        <Wrapper ref={firstSection}>
          <MediaCell1>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
          </MediaCell1>
          <MediaCell2>
            <QuoteElement
              message="One of the things I love best about Amsterdam is how you can cycle everywhere. You don’t need a car here at all."
              name="Anna"
              position="HR Coordinator"
              modifier="left-4 bottom-4 tablet:left-auto tablet:right-[26rem] tablet:bottom-[35rem]"
            />
            <ImageWrapper $size="small" $margin="left">
              <PimcoreImage image={getElementImage(content[1])} modifier="w-full rounded-lg" withAspectRatio />
            </ImageWrapper>
          </MediaCell2>
          <MediaCell3>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[2]) }} />
            </ParagraphWrapper>
          </MediaCell3>
          <MediaCell4>
            <FactSingleWrapper>
              <FactsBox facts={[getElementFact(content[3])]} />
            </FactSingleWrapper>
          </MediaCell4>
          <MediaCell5>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[4])} modifier="w-full rounded-lg" withAspectRatio />
            </ImageWrapper>
          </MediaCell5>
          <MediaCell6>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
            <FactWrapper>
              <FactsBox facts={[getElementFact(content[6])]} modifier="tablet:pr-8" variant="lineheight" />
              <FactsBox facts={[getElementFact(content[7])]} modifier="tablet:pr-8" variant="lineheight" />
            </FactWrapper>
          </MediaCell6>
        </Wrapper>
      </ScrollWrapper>
      <ScrollWrapper>
        <Wrapper ref={secondSection}>
          <MediaCell7>
            <HeadlineAmsterdam dangerouslySetInnerHTML={{ __html: getElementText(content[8]) }} />
          </MediaCell7>
          <MediaCell8>
            <Video placeholder={getElementImage(content[9])} video={getElementVideo(content[9])} />
          </MediaCell8>
          <MediaCell9>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[10]) }} />
          </MediaCell9>
        </Wrapper>
      </ScrollWrapper>
    </>
  );
};

export default AmsterdamTransport;

const ScrollWrapper = styled.div.attrs({ className: "scrollspy" })``;

const Wrapper = styled.div.attrs({
  className: " tablet:grid tablet:grid-cols-12 tablet:gap-4 px-4 mb-16 tablet:mb-36 ",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(3, auto);
  }
`;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 tablet:pt-4",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-1 tablet:col-span-6 tablet:row-start-1 tablet:row-span-6 mb-8 tablet:mb-0 relative",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-3 tablet:row-span-1",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-5 tablet:row-span-4 mb-8 tablet:mb-0",
})``;
const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-8 tablet:row-span-1 tablet:pb-4 tablet:pr-10",
})``;

const FactSingleWrapper = styled.div.attrs({
  className: "mt-8 mb-12 tablet:mb-0 w-3/5 tablet:pr-16",
})``;
const FactWrapper = styled.div.attrs({
  className: "mt-8 mb-12 tablet:mb-0 grid grid-cols-2 tablet:w-full tablet:grid-flow-col gap-16 pr-8 tablet:pr-0",
})``;
const ParagraphWrapper = styled.div.attrs({ className: "tablet:pr-20" })``;

const ImageWrapper = styled.div.attrs<ImageWrapperProps>(({ $size, $mobileHidden, $withGap, $margin }) => ({
  className: `${$margin === "left" ? "ml-auto" : "mr-auto"} ${$size === "small" ? "tablet:w-11/12" : "tablet:w-full"}${
    $mobileHidden ? " hidden tablet:block" : ""
  }${$withGap ? " mb-4" : ""}`,
}))<ImageWrapperProps>``;

const HeadlineAmsterdam = styled.h2.attrs({
  className: "font-light text-white leading-17 text-6xl mb-8 tablet:text-subHeadline",
})``;
const AmsterdamWrapper = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 mb-16 px-5 tablet:mb-36 tablet:grid-flow-col",
})``;
const MediaCell7 = styled.div.attrs({
  className:
    "tablet:col-span-5 tablet:col-start-8 tablet:row-start-1 tablet:row-span-1 mb-8 tablet:ml-auto tablet:mb-0 tablet:ml-20",
})``;
const MediaCell8 = styled.div.attrs({
  className:
    "tablet:col-span-7 tablet:col-start-1 tablet:row-start-1 tablet:row-span-2 mb-8 tablet:ml-auto tablet:mb-0 tablet:w-11/12",
})``;
const MediaCell9 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-5 tablet:row-start-2 tablet:row-span-1 tablet:ml-20 tablet:pr-28",
})``;

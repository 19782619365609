import React from "react";
import styled from "styled-components";
import FactsBox from "../../../components/facts-box";
import { PimcoreImage } from "../../../components/pimcore-image";
import { Headline2, Headline3, Paragraph } from "../../../components/typography";
import { PageElement } from "../../../graphql/page-common";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";
import { getElementFact, getElementImage, getElementText } from "../../../utilities/pimcore-data-transformer";

interface AmsterdamLivingProps {
  content: Array<PageElement>;
}

const AmsterdamLiving: React.FC<AmsterdamLivingProps> = ({ content }) => {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(
      wrapper.current,
      [
        wrapper?.current?.children[0] || null,
        wrapper?.current?.children[1].children[0] || null,
        wrapper?.current?.children[1].children[1].children || null,
      ],
      matchMobile
    );

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper ref={wrapper}>
      <HeadlineWrapper>
        <Headline dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
      </HeadlineWrapper>
      <Section>
        <ImageWrapper>
          <PimcoreImage image={getElementImage(content[1])} modifier="w-full rounded-lg" withAspectRatio />
        </ImageWrapper>
        <ContentWrapper>
          <Headline3Wrapper>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[2]) }} />
          </Headline3Wrapper>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
          <FactSingleWrapper>
            <FactsBox facts={[getElementFact(content[4])]} />
          </FactSingleWrapper>
        </ContentWrapper>
      </Section>
    </Wrapper>
  );
};

export default AmsterdamLiving;

const HeadlineWrapper = styled.div.attrs({
  className: "grid tablet:grid-cols-12",
})``;
const Wrapper = styled.div.attrs({ className: "px-5" })``;
const Headline = styled(Headline2).attrs({
  className: "tablet:col-span-4 tablet:col-start-7 tablet:mb-30",
})``;
const Section = styled.section.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 tablet:grid-flow-col mb-12 tablet:mb-32",
})``;
const ImageWrapper = styled.div.attrs({
  className: "tablet:col-span-6 tablet:col-start-6 tablet:col-end-12 tablet:order-1 mb-8 tablet:mb-0",
})``;

const ContentWrapper = styled.div.attrs({
  className: "mb-8 tablet:col-start-2 tablet:col-end-6 tablet:col-span-4 tablet:order-2 tablet:pr-14",
})``;

const FactSingleWrapper = styled.div.attrs({
  className: "mt-8 mb-12 tablet:mb-0 w-3/5 tablet:w-4/5 tablet:pr-16",
})``;

const Headline3Wrapper = styled.div.attrs({ className: "mb-8" })``;

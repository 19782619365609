import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/styles";
import { Headline3, Paragraph } from "../../../components/typography";
import FactsBox from "../../../components/facts-box";
import { PimcoreImage } from "../../../components/pimcore-image";
import QuoteElement from "../../../components/quote-element/quote-element";
import { PageElement } from "../../../graphql/page-common";
import { getElementFact, getElementImage, getElementText } from "../../../utilities/pimcore-data-transformer";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../../utilities/animation-helper";

interface AmsterdamInfoProps {
  content: Array<PageElement>;
}

interface ImageWrapperProps {
  $size?: "small";
  $mobileHidden?: boolean;
  $withGap?: boolean;
  $margin?: "left";
}

const AmsterdamInfo: React.FC<AmsterdamInfoProps> = ({ content }) => {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    const firstChildrenIndexSection = [0, 1, 2, 4, 3, 6, 5, 9, 7, 10, 11, 8, 12];
    const firstChildrenIndexSectionMobile = [0, 1, 3, 4, 6, 7, 8, 11];
    const firstIndexSection = matchMobile ? firstChildrenIndexSectionMobile : firstChildrenIndexSection;

    tl.current = onScrollAnimationDesktop(
      wrapper.current,
      returnRefArray(wrapper.current, firstIndexSection, [1, 11]),
      matchMobile
    );

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper ref={wrapper}>
      <MediaCell1>
        <ImageWrapper>
          <PimcoreImage image={getElementImage(content[0])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell1>
      <MediaCell2_1>
        <ParagraphWrapper>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[1]) }} />
        </ParagraphWrapper>
        <FactWrapper>
          <FactsBox
            float="left"
            modifier="pr-[1.65rem] tablet:mr-12 tablet:last:mr-0"
            facts={[getElementFact(content[2]), getElementFact(content[3])]}
          />
        </FactWrapper>
      </MediaCell2_1>
      <MediaCell2>
        <ImageWrapper $size="small" $margin="left" $mobileHidden>
          <PimcoreImage image={getElementImage(content[4])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell2>
      <MediaCell4_1>
        <HeadlineWrapper>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
        </HeadlineWrapper>
      </MediaCell4_1>
      <MediaCell3>
        <QuoteElement
          message="My favourite restaurant is De Kas. The food is amazing there."
          name="Mariya"
          position="HR Generalist"
          modifier="left-4 bottom-4 tablet:left-96 tablet:bottom-[28rem]"
          left={true}
        />
        <ImageWrapper>
          <PimcoreImage image={getElementImage(content[6])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell3>
      <MediaCell4 />

      <MediaCell4_2>
        <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
      </MediaCell4_2>
      <MediaCell4_3>
        <FactSingleWrapper>
          <FactsBox facts={[getElementFact(content[8])]} />
        </FactSingleWrapper>
      </MediaCell4_3>
      <MediaCell8>
        <QuoteElement
          message="I love the Amsterdamse Bos - it’s a big park south-west of Amsterdam."
          name="Lycon"
          position="Facility Manager"
          modifier="left-4 bottom-4 tablet:left-auto tablet:right-[23rem] tablet:bottom-72"
        />
        <ImageWrapper>
          <PimcoreImage image={getElementImage(content[9])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell8>
      <MediaCell5>
        <QuoteElement
          message="Flying Dutchman - the best place for cocktails."
          name="Dao"
          position="Management Assistant"
          modifier="hidden tablet:flex tablet:right-[22rem] tablet:bottom-44"
        />
        <ImageWrapper $size="small" $mobileHidden>
          <PimcoreImage image={getElementImage(content[10])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell5>
      <MediaCell6>
        <ImageWrapper>
          <PimcoreImage image={getElementImage(content[11])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell6>
      <MediaCell7>
        <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[12]) }} />
        <FactWrapper>
          <FactsBox
            float="left"
            modifier="mr-12 last:mr-0"
            facts={[getElementFact(content[13]), getElementFact(content[14])]}
          />
        </FactWrapper>
      </MediaCell7>
      <MediaCell9>
        <QuoteElement
          message="The Van Gogh museum is amazing."
          name="Sammy"
          position="Hospitality & Events Manager"
          modifier="hidden tablet:flex tablet:left-[22rem] tablet:bottom-[22rem]"
          left={true}
        />
        <ImageWrapper $size="small" $mobileHidden>
          <PimcoreImage image={getElementImage(content[15])} modifier={"w-full rounded-lg"} withAspectRatio />
        </ImageWrapper>
      </MediaCell9>
    </Wrapper>
  );
};

export default AmsterdamInfo;

const Wrapper = styled.div.attrs({
  className: " tablet:grid tablet:grid-cols-12 tablet:gap-4 px-4 mb-14 tablet:mb-36",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(17, auto);
  }
`;

const MediaCell1 = styled.div.attrs({
  className: " tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-2 mb-8 tablet:mb-0",
})``;
const MediaCell2_1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-3 ",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-3 tablet:row-span-3",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-4 tablet:row-span-3 mb-8 tablet:mb-0 relative",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:row-start-6 tablet:col-start-2 tablet:col-span-4 tablet:pr-7 tablet:row-span-4",
})``;
const MediaCell4_1 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-5 tablet:row-span-1",
})``;
const MediaCell4_2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-6 tablet:row-span-2 tablet:pr-1",
})``;
const MediaCell4_3 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-8 tablet:row-span-1",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-4 tablet:row-start-7 tablet:row-span-5 relative",
})``;
const MediaCell6 = styled.div.attrs({
  className:
    "hidden tablet:block tablet:col-start-2 tablet:col-span-5 tablet:row-start-11 tablet:row-span-4 mb-8 tablet:mb-0 tablet:pt-16",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-12 tablet:row-span-4 tablet:pr-4 tablet:pt-16",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:row-start-15 tablet:row-span-4 tablet:col-start-3 tablet:col-span-4 relative mb-8 tablet:mb-0",
})``;
const MediaCell9 = styled.div.attrs({
  className: "tablet:row-start-16 tablet:row-span-4 tablet:col-start-7 tablet:col-span-5 relative",
})``;
const HeadlineWrapper = styled.div.attrs({ className: "pr-14 tablet:pr-20" })``;
const FactWrapper = styled.div.attrs({
  className: "mt-8 mb-12 tablet:mb-0 tablet:w-full",
})``;
const FactSingleWrapper = styled.div.attrs({
  className: "mt-8 mb-12 tablet:mb-0 w-3/5 tablet:w-4/5",
})``;
const ImageWrapper = styled.div.attrs<ImageWrapperProps>(({ $size, $mobileHidden, $withGap, $margin }) => ({
  className: `${$margin === "left" ? "ml-auto" : "mr-auto"} ${$size === "small" ? "tablet:w-10/12" : "tablet:w-full"}${
    $mobileHidden ? " hidden tablet:block" : ""
  }${$withGap ? " mb-4" : ""}`,
}))<ImageWrapperProps>``;
const ParagraphWrapper = styled.div.attrs({ className: "tablet:pr-8" })``;
